
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91case_id_93hE8cv4t9PNWm9RtBHzCA8bFgn5wkQNIsYtK8jjrY_45TQMeta } from "/var/www/dev/app/pages/cases/[case_id].vue?macro=true";
import { default as _91company_id_931biKgNXbvOhONMgVAFvp4lO_hUv9jQfqtGAHsCDZsgoMeta } from "/var/www/dev/app/pages/company/[company_id].vue?macro=true";
import { default as indexRTo5cSAOkQPTSJ_4u2HpHMjyB2xecgYQoXN_4590bFjKQMeta } from "/var/www/dev/app/pages/index.vue?macro=true";
import { default as logind2FePGdQZd68hGlAb66uomAKBz4RqI8OWGQGwqUzyM0Meta } from "/var/www/dev/app/pages/login.vue?macro=true";
import { default as _91case_id_93wOuO_454ejAdFq1B_u42pQCgr7xgLwrfIALB5ByXFSLlYMeta } from "/var/www/dev/app/pages/projects/[project_id]/economics/[case_id].vue?macro=true";
import { default as compare_dnIHUe6bi_n_jyV8oLgIgNn_45q6EYXrqKTqDjntkWxkMeta } from "/var/www/dev/app/pages/projects/[project_id]/economics/compare.vue?macro=true";
import { default as indexhdLmIqhBu7KhWC3rJ797e5cMIEobM9_ksvFChLLTA60Meta } from "/var/www/dev/app/pages/projects/[project_id]/economics/index.vue?macro=true";
import { default as _91case_id_938cAfeNjRvQ7YyPS_45mcqh8OjkmDxvMQxGnbXvQClyR_gMeta } from "/var/www/dev/app/pages/projects/[project_id]/engineering/[case_id].vue?macro=true";
import { default as indexd8SSEi7VfniLxU4tuc78mc8IZTCRSo0vAYkfosiRa0QMeta } from "/var/www/dev/app/pages/projects/[project_id]/engineering/index.vue?macro=true";
import { default as indexev14_WOqdGj3Oo3V8iJmYAxAbz635dLf9MuTN73uiR0Meta } from "/var/www/dev/app/pages/projects/[project_id]/index.vue?macro=true";
import { default as _91case_id_93xeJ8ybQ_cXKUw94LRH3ZYRNuuUROgCu5R_SxuhUksy0Meta } from "/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/cases/[case_id].vue?macro=true";
import { default as index6nzWwXcd_MrGcyfXZQR72TPVMZWO5OUWUXczpczucYYMeta } from "/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/index.vue?macro=true";
import { default as indextE_2njyJ9FNrDGEfkJYPX0Mp_45jD_X17GqHY9hYT2QlkMeta } from "/var/www/dev/app/pages/projects/[project_id]/layers/index.vue?macro=true";
import { default as _91case_id_93EQEHfnL2sb_yA1HXDCNSkmaihuo_AsfJB1mN_2OlXGcMeta } from "/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/cases/[case_id].vue?macro=true";
import { default as indexcGFM9BATKcxxQ4Uqp2pG2SPl4T0DyokXw2ksPJ6MovgMeta } from "/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/index.vue?macro=true";
import { default as indexrE4Hf65_ZYimRVfifbbOMcp6r00iAGvjkGOE3IgkoH0Meta } from "/var/www/dev/app/pages/projects/[project_id]/objects/index.vue?macro=true";
import { default as _91tax_id_93Qd4yayx_45zuRSETvPpIuIyPb0kSWJI8hlacGhQkSow8QMeta } from "/var/www/dev/app/pages/projects/[project_id]/taxes/[tax_id].vue?macro=true";
import { default as indexm_qlltHXhuAGKU1DfW5zILVT5PabdaxE0Y8eWkcQyNwMeta } from "/var/www/dev/app/pages/projects/index.vue?macro=true";
import { default as indexMmrAGStBuc0sOX7YubMTyjVVprdrDqStqoG3MGZQt24Meta } from "/var/www/dev/app/pages/system/index.vue?macro=true";
import { default as jobsgF7pj1Pd7DYPM_45wmG3_45hxYnq9U9xM0sooKUm1J3XgPwMeta } from "/var/www/dev/app/pages/system/jobs.vue?macro=true";
import { default as _91user_id_93nTVZN5biAi_oUgKUQO3FSTys0fkyV8WxjHKG_45ZHA9RoMeta } from "/var/www/dev/app/pages/user/[user_id].vue?macro=true";
export default [
  {
    name: "cases-case_id",
    path: "/cases/:case_id()",
    meta: _91case_id_93hE8cv4t9PNWm9RtBHzCA8bFgn5wkQNIsYtK8jjrY_45TQMeta || {},
    component: () => import("/var/www/dev/app/pages/cases/[case_id].vue")
  },
  {
    name: "company-company_id",
    path: "/company/:company_id()",
    meta: _91company_id_931biKgNXbvOhONMgVAFvp4lO_hUv9jQfqtGAHsCDZsgoMeta || {},
    component: () => import("/var/www/dev/app/pages/company/[company_id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/dev/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logind2FePGdQZd68hGlAb66uomAKBz4RqI8OWGQGwqUzyM0Meta || {},
    component: () => import("/var/www/dev/app/pages/login.vue")
  },
  {
    name: "projects-project_id-economics-case_id",
    path: "/projects/:project_id()/economics/:case_id()",
    meta: _91case_id_93wOuO_454ejAdFq1B_u42pQCgr7xgLwrfIALB5ByXFSLlYMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/economics/[case_id].vue")
  },
  {
    name: "projects-project_id-economics-compare",
    path: "/projects/:project_id()/economics/compare",
    meta: compare_dnIHUe6bi_n_jyV8oLgIgNn_45q6EYXrqKTqDjntkWxkMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/economics/compare.vue")
  },
  {
    name: "projects-project_id-economics",
    path: "/projects/:project_id()/economics",
    meta: indexhdLmIqhBu7KhWC3rJ797e5cMIEobM9_ksvFChLLTA60Meta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/economics/index.vue")
  },
  {
    name: "projects-project_id-engineering-case_id",
    path: "/projects/:project_id()/engineering/:case_id()",
    meta: _91case_id_938cAfeNjRvQ7YyPS_45mcqh8OjkmDxvMQxGnbXvQClyR_gMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/engineering/[case_id].vue")
  },
  {
    name: "projects-project_id-engineering",
    path: "/projects/:project_id()/engineering",
    meta: indexd8SSEi7VfniLxU4tuc78mc8IZTCRSo0vAYkfosiRa0QMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/engineering/index.vue")
  },
  {
    name: "projects-project_id",
    path: "/projects/:project_id()",
    meta: indexev14_WOqdGj3Oo3V8iJmYAxAbz635dLf9MuTN73uiR0Meta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/index.vue")
  },
  {
    name: "projects-project_id-layers-layer_id-cases-case_id",
    path: "/projects/:project_id()/layers/:layer_id()/cases/:case_id()",
    meta: _91case_id_93xeJ8ybQ_cXKUw94LRH3ZYRNuuUROgCu5R_SxuhUksy0Meta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/cases/[case_id].vue")
  },
  {
    name: "projects-project_id-layers-layer_id",
    path: "/projects/:project_id()/layers/:layer_id()",
    meta: index6nzWwXcd_MrGcyfXZQR72TPVMZWO5OUWUXczpczucYYMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/layers/[layer_id]/index.vue")
  },
  {
    name: "projects-project_id-layers",
    path: "/projects/:project_id()/layers",
    meta: indextE_2njyJ9FNrDGEfkJYPX0Mp_45jD_X17GqHY9hYT2QlkMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/layers/index.vue")
  },
  {
    name: "projects-project_id-objects-object_id-cases-case_id",
    path: "/projects/:project_id()/objects/:object_id()/cases/:case_id()",
    meta: _91case_id_93EQEHfnL2sb_yA1HXDCNSkmaihuo_AsfJB1mN_2OlXGcMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/cases/[case_id].vue")
  },
  {
    name: "projects-project_id-objects-object_id",
    path: "/projects/:project_id()/objects/:object_id()",
    meta: indexcGFM9BATKcxxQ4Uqp2pG2SPl4T0DyokXw2ksPJ6MovgMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/objects/[object_id]/index.vue")
  },
  {
    name: "projects-project_id-objects",
    path: "/projects/:project_id()/objects",
    meta: indexrE4Hf65_ZYimRVfifbbOMcp6r00iAGvjkGOE3IgkoH0Meta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/objects/index.vue")
  },
  {
    name: "projects-project_id-taxes-tax_id",
    path: "/projects/:project_id()/taxes/:tax_id()",
    meta: _91tax_id_93Qd4yayx_45zuRSETvPpIuIyPb0kSWJI8hlacGhQkSow8QMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/[project_id]/taxes/[tax_id].vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexm_qlltHXhuAGKU1DfW5zILVT5PabdaxE0Y8eWkcQyNwMeta || {},
    component: () => import("/var/www/dev/app/pages/projects/index.vue")
  },
  {
    name: "system",
    path: "/system",
    meta: indexMmrAGStBuc0sOX7YubMTyjVVprdrDqStqoG3MGZQt24Meta || {},
    component: () => import("/var/www/dev/app/pages/system/index.vue")
  },
  {
    name: "system-jobs",
    path: "/system/jobs",
    meta: jobsgF7pj1Pd7DYPM_45wmG3_45hxYnq9U9xM0sooKUm1J3XgPwMeta || {},
    component: () => import("/var/www/dev/app/pages/system/jobs.vue")
  },
  {
    name: "user-user_id",
    path: "/user/:user_id()",
    meta: _91user_id_93nTVZN5biAi_oUgKUQO3FSTys0fkyV8WxjHKG_45ZHA9RoMeta || {},
    component: () => import("/var/www/dev/app/pages/user/[user_id].vue")
  }
]